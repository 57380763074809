// vendors
import React from 'react';
import * as Yup from 'yup';
import '@wldlab/yup-phone';

// components
import Layout from '@components/Layout';

// views
import HeroSection from '@views/ContactFormPageView/HeroSection';
import FormSection from '@views/ContactFormPageView/FormSection';
import SEO from '@src/components/SEO';

const validateSchema = Yup.object().shape({
  profile: Yup.string().required('Select your profile'),
  subject: Yup.string().required('Select the subject of your request'),
  lastName: Yup.string()
    .required('Enter your last name')
    .min(2, 'The entered last name is too short (min. 2 characters)')
    .max(50, 'The entered last name is too long (max. 50 characters)'),
  firstName: Yup.string()
    .required('Enter your first name')
    .min(2, 'The entered first name is too short (min. 2 characters)')
    .max(50, 'The entered first name is too long (max. 50 characters)'),
  phone: Yup.string()
    .required('Enter your phone number')
    .phone('CA', 'Enter a valid phone number'),
  email: Yup.string()
    .required('Enter your email address')
    .email('Enter a valid email address'),
  message: Yup.string().required('Enter your message'),
  url: Yup.string().url('Enter a valid URL'),
});

const ContactFormPage = () => {
  const langLinks = [{ langKey: 'fr', href: '/formulaire-de-contact' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO lang='en' langLinks={langLinks} title='Contact Form' />

      <HeroSection>
        <h1>Contact form</h1>
      </HeroSection>

      <FormSection validateSchema={validateSchema} />
    </Layout>
  );
};

export default ContactFormPage;
